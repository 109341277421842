// import PropTypes from 'prop-types';
import styles from './InfoText.module.scss';

const InfoText = ({ html = '' }) => {
    return (
        <div className={styles['InfoText']}>
            <div className={styles['InfoText__Container']}>
                <div className={styles['InfoText__Inner']}>
                    <div
                        className={styles['InfoText__RichText']}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </div>
            </div>
        </div>
    );
};

InfoText.propTypes = {};

export default InfoText;
